@import "../../../../../../../common/styles";

div.horizontalLabel {
  flex-direction: row;
  align-items: center;
}

.horizontalLabel {
  margin-right: 30px;
}

.twoColumns {
  width: calc(50% - 8px);
}

.password input {
  font-family: password !important;
}

.twoMainColumns {
  width: calc(50% - 13px);
}

.shippingAccountWrapper {
  margin-bottom: 24px;
}

.formRow {
  display: flex;
  justify-content: space-between;

  :global(.MuiFormControl-root) {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.shippingDetails {
  padding-bottom: 24px;
}

.shippingDetailsLabel {
  @extend %body16-600;
  color: $black;
  margin: 0 0 16px;
}

.shippingDetailsSubtitile {
  @extend %body16;
  color: black;
  margin: 0;
}

.shoppingAddressSection {
  margin-bottom: 16px;
}

.myCompanyWrapper {
  margin-bottom: 32px;
}

.warningHint {
  @extend %body16;
  margin: 0;
  color: $black;
}

.shippingPreferences {
  width: calc(25% - 16px);
}
