@import "../../../../common/styles/index";

.servicesWrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 100px;

  .headerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;
  }

  .title {
    @extend %h1;
    color: $black;
    margin: 0;
  }
}
