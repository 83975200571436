@import "../../../../common/styles";

.menuWrapper {
  display: none;

  @media screen and (max-width: $extra-small) {
    display: block;
  }
}

.drawerWrapper {
  margin: 0;

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
    height: 90%;
  }

  .languageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .closeBtn {
    padding: 0;
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  .authBtns {
    align-self: flex-end;
  }
}
