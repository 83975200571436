@import "../../../../common/styles/index";

.loginWrapper {
  margin: 0 auto 100px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;

  .formWrapper {
    flex: 1;
    max-width: 42%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    @media screen and (max-width: 1480px) {
      max-width: 48%;
    }
  }

  .imgPreview {
    max-width: 50%;
    background: url("../../../../assets/img/register.png");
    background-size: cover;
    flex: 1;
  }

  .errorWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .title {
    @extend %h3;
    margin: 0 0 20px;
  }

  .registerLink {
    display: block;
    text-align: right;
  }

  .forgotPassword {
    display: block;
    text-align: right;
    font-weight: 700;
  }

  .registerHint {
    font-weight: 700;
  }

  .loginBtn {
    margin-left: auto;
    display: flex;
  }

  .footerLinks {
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }
}
