@import "../../../../common/styles/index";

.contactUsWrapper {
  display: flex;
  text-align: left;
  margin: 0 auto 100px;
  justify-content: center;
  // todo
  // justify-content: space-between;

  .imgPlaceholder {
    width: 836px;
    margin-left: calc((100vw - 1380px + 20px) / -2);
    margin-right: 20px;
    background: url("../../../../assets/img/contact-us.png");
    background-size: cover;

    @media screen and (max-width: 1380px) {
      margin-left: -20px;
    }
  }

  .title {
    @extend %h4;
    color: $black;
    margin: 0 0 25px;
    text-align: center;
  }

  .contactForm {
    max-width: 680px;
  }

  .contactBtn {
    margin-top: 16px;
  }

  .formRow {
    display: flex;
    justify-content: space-between;

    > div {
      max-width: calc(50% - 8px);
    }
  }
}

.statusWrapper {
  margin-bottom: 100px;
  .successTitle {
    @extend %h4;
    color: $black;
    margin: 0 48px;
  }
  .errorTitle {
    @extend %h4;
    color: $error;
    margin: 0 48px;
  }
}
