@import "../../../../../../common/styles";

.itemWrapper {
  display: flex;
  flex-direction: column;
  width: 92%;
  min-height: 220px;
  margin: 14px 0 0;

  &:hover {
    box-shadow: 0 0 11px $light-grey;
  }
}

.title {
  @extend %h6;
  color: $blue;
  background-color: $light-grey-2;
  padding: 20px;
  margin: 0;
}

.price {
  @extend %h5;
  font-weight: 700;
  padding: 20px;
  margin: 0;
}

.description {
  padding: 0 20px 20px;
  margin: 0;
}
