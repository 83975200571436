@import "../../../../../../common/styles/index";

.invitationError {
  max-width: 800px;
  margin: 120px auto 0 auto;
  @extend %h5;
  color: $black;
}

span.loader {
  margin-top: 120px;
}

.registerWrapper {
  margin: 0 auto 100px;
  padding-top: 30px;

  :global(.MuiFormControl-root) {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .errorWrapper {
    margin-bottom: 16px;
  }

  .stepper {
    margin-bottom: 60px;
    max-width: 682px;
  }

  .registerContent {
    display: flex;
    justify-content: space-between;
  }

  .imgPreview {
    max-width: 50%;
    background: url("../../../../../../assets/img/register.png");
    background-size: cover;
    flex: 1;
  }

  .formWrapper {
    flex: 1;
    max-width: 42%;
    position: relative;
    text-align: left;
    padding: 0 0 160px 20px;

    @media screen and (max-width: 1480px) {
      max-width: 48%;
    }
  }

  .label {
    @extend %body14;
    color: $black;
    margin: 8px 0 0 0;
  }

  .mt24 {
    margin-top: 24px;
  }

  .checkboxWrapper {
    margin: 20px 0 5px 0;
  }

  .phoneCheckboxWrapper {
    margin-bottom: -8px;
  }

  .error {
    color: $error;
  }

  .checkboxLabel {
    @extend %body14;
    margin: 0;
    color: $black;
  }

  .formRow {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(50% - 8px);
    }
    :global(.MuiFormControl-root) {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .title {
    @extend %h3;
    text-align: center;
    margin: 0 0 20px;
  }

  .footerWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 32px;
    padding-right: 12px;

    .actionWrapper {
      display: flex;
      justify-content: space-between;
    }

    .nextBtn {
      margin-left: auto;
    }
  }
}
