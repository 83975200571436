@import "../../../../../../common/styles";

span.loader {
  flex: 1;
  align-self: center;
  display: flex;
}

.modalWrapper {
  width: 90vw;
  max-height: 90vh;
}

.previewWrapper {
  margin: 10px;
}

.previewFooter {
  display: flex;
  justify-content: right;
  margin-top: 32px;
}

button.closeButton {
  margin-right: 20px;
}
