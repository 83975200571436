.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: flex-start;

  .inputRoot {
    background: white;
    margin-right: 16px;

    input {
      text-align: right;
    }
  }

  .button {
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 12px 34px;
  }
}
