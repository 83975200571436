.uploadItem {
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.uploadItemRight {
  display: flex;
  flex: 1;

  > section {
    flex: 1;
  }
}

div.reportType {
  margin-right: 24px;
  min-width: 200px;
}

div.reportName {
  margin-right: 24px;
  min-width: 300px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
