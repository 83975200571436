@import "../../../../../../common/styles";

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
  justify-content: flex-start;

  .inputRoot {
    width: 240px;
    background: white;
    margin-right: 16px;
  }

  .button {
    padding: 28px 34px;
  }
}
