@import "../../../../../../../common/styles";

.verticalView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    margin-top: 0;
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  :global(.MuiFormControlLabel-root) {
    margin-left: 0;
  }

  :global(.MuiButtonBase-root) {
    padding: 0 10px 0 0;
  }

  .dispose {
    margin-bottom: 22px;
  }
}

.notes {
  min-width: 300px;
  width: 50%;
}
