@import "../../../../../../../common/styles";

div.horizontalLabel {
  flex-direction: row;
  align-items: center;
}

.horizontalLabel {
  margin-right: 30px;
}
