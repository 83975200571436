@import "../../styles";

.main div:global(.MuiDataGrid-columnHeader) {
  &:focus {
    outline: none;
  }

  :global(.MuiDataGrid-columnHeaderTitle) {
    @extend %body16;
    padding: 10px 18px;
    color: $black;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
}

div.gridCell:global(.MuiDataGrid-cell) {
  &:global(.cell-blue-text) {
    color: $blue-2;
  }

  &:global(.cell-green-text) {
    color: $green;
  }

  &:global(.cell-blue-background) > span {
    @extend %body14-600;
    background: $blue-2;
    color: $white;
    border-radius: 36px;
    padding: 6px 16px;
  }

  &:global(.cell-green-background) > span {
    @extend %body14-600;
    background: $green;
    color: $white;
    border-radius: 36px;
    padding: 6px 16px;
  }

  &:global(.cell-light-grey),
  &:global(.cell-light-grey) > span {
    color: $dark-grey;
  }
}

.gridCell:global(.MuiDataGrid-cell.MuiDataGrid-withBorderColor) {
  @extend %body14-400;
  padding: 10px 28px;
  color: $black;
  position: relative;

  &:focus,
  &:focus-within {
    outline: none;
  }
}

div.gridRow:global(.MuiDataGrid-row) {
  &:hover {
    background: $grid-hover;

    .deleteBtn {
      opacity: 1;
      background-color: $white;
    }
  }
}

section.btnsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

button.deleteBtn {
  right: 40px;
  opacity: 0;
  background: $white;
}

button.customBtn {
  right: 60px;
}

.cellWithAction {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
