@import "../../../../../../common/styles";

button.footer {
  position: absolute;
  bottom: 4px;
  right: 0;
}

.formFooter {
  display: flex;
  justify-content: right;
  margin-top: 32px;
}

span.siteTitle {
  @extend %body22;
  text-transform: uppercase;
  margin-left: 0;
  color: $blue;
}

button.previewButton {
  margin-right: 20px;
}
