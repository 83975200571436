@import "../../../../../../common/styles";

.wrapper {
  padding: 20px;
  margin-bottom: 30px;

  li {
    list-style: none;
    margin-bottom: 12px;
  }
}

.paymentMethods {
  max-width: 450px;
}

.pmTextSection {
  display: flex;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.plusIcon {
  width: 16px;
  padding-right: 8px;
}
