@import "../../../../../../common/styles";

.twoColumns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;

  > * {
    max-width: calc(50% - 12px);
  }
}

.tinyMargin {
  display: flex;
  margin-bottom: 0;
}

.bodyText {
  @extend %body14;
  color: $black;
  margin-right: 26px;
}

.boldText {
  @extend %body14;
  font-weight: 600;
  color: $black;
}

.brandsWrapper {
  margin-top: 16px;
  width: calc(50% - 12px);
}
