@import "../../../../common/styles";

.footerWrapper {
  background: $black;

  .infoWrapper {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    align-items: flex-start;

    @media screen and (max-width: $extra-small) {
      flex-direction: column;
      padding: 10px 0;
    }
  }

  .copyright {
    display: block;
    border-top: 1px solid $white;
    @extend %body1;
    padding: 20px;
    color: $white;
  }

  .contactInfo {
    @extend %body1;
    list-style-type: none;
    color: $white;
    text-align: left;

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: 28px;
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    a {
      text-decoration: none;
      color: $white;
    }

    p {
      margin: 0;
    }

    @media screen and (max-width: $extra-small) {
      padding-left: 0;
      margin: 16px 0 8px 0;

      li {
        svg {
          margin-right: 12px;
        }
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .links {
    @extend %body2;
    list-style-type: none;
    color: $white;
    text-align: left;

    a {
      text-decoration: none;
      color: white;
    }
    li {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    @media screen and (max-width: $extra-small) {
      padding-left: 0;
      margin: 8px 0 16px 0;

      li {
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .footerLogo {
    height: 80px;
    display: flex;
    align-self: center;

    @media screen and (max-width: $extra-small) {
      display: none;
    }
  }

  .footerSmallLogo {
    height: 80px;
    align-self: center;
    display: none;

    @media screen and (max-width: $extra-small) {
      display: flex;
    }
  }
}
