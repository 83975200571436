@import "../../../../common/styles/index";

.mainWrapper {
  min-height: 500px;
  height: calc(100vh - 104px);
  background: url("../../../../assets/img/main.png");
  background-size: cover;
  margin-bottom: 100px;
  position: relative;

  .actionBlock {
    position: absolute;
    bottom: 0;
    padding: 100px 100px 70px;
    right: 0;
    left: 0;

    hr {
      height: 10px;
      background: $white;
      margin: 60px 0 52px 0;
    }

    .titleWrapper {
      text-align: left;
    }

    .titleSection {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    .titleLarge {
      @extend %h1;
      color: $white;
      margin-right: 10px;
    }

    .actionBlockBottom {
      display: flex;
      justify-content: space-between;

      .solutionForBrand {
        @extend %h4;
        color: $white;
      }
      .learnMoreBtn {
        margin-right: 40px;
      }
    }
  }
}
