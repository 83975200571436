.wrapper {
  b {
    margin-right: 14px;
  }

  hr {
    width: 400px;
    margin-bottom: 24px;
  }
}
