@import "../../../../../../common/styles";

.sectionContent {
  display: flex;
  align-items: flex-start;
  padding-bottom: 24px;
}

.hr {
  margin-top: 28px;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid $light-grey;
}

.sectionSubTitle {
  @extend %body16-700;
  color: $black;
  padding-bottom: 24px;
  margin: 0;
}

.listItemName {
  @extend %body14;
  color: $black;
}

.textBold {
  font-weight: 700;
}

.listItemValue {
  @extend %body16-600;
  color: $black;
}

.listItemValueNormal {
  font-weight: normal;
}

.subtext {
  @extend %body14;
  color: $black;
  margin: 0 0 18px 0;
}
