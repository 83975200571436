@import "../../../../../../common/styles";

.modalWrapper {
  width: 500px;
}

.footerLabel {
  @extend %body16;
  color: $black;
  margin: 0 0 16px;
}

.modalFooterActions {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}
