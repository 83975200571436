@import "../../../styles";

.checkboxWrapper {
  margin: 20px 0 5px 0;
}

.error {
  color: $error;
}

.checkboxLabel {
  @extend %body14;
  margin: 0;
  color: $black;
}
