@import "../../../styles";

div.phoneWrapper {
  display: flex;
  flex-direction: row;

  &:global(.MuiFormControl-root) {
    margin-top: 0;
  }

  .countryRoot {
    min-width: 126px;
    margin-right: 8px;
    margin-top: 16px;
  }
}

.countryImage {
  margin-right: 4px;
}

.countryImagePreview {
  margin-right: 2px;
  width: 20px;
}
