@import "../../../../../../../common/styles";

.formRow {
  display: flex;
  justify-content: space-between;

  :global(.MuiFormControl-root) {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.twoColumns {
  width: calc(50% - 8px);
}

.twoMainColumns {
  width: calc(50% - 13px);
}

.label {
  @extend %body14;
  color: $black;
  margin-bottom: 5px;
  margin-top: 24px;
}
