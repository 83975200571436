@import "../../styles";

.modalWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 30px;
}

.modalContent {
  overflow: auto;
}

.title {
  @extend %h5;
  margin: 0 0 20px;
}
