@import "../../../../../../common/styles";

.row {
  display: flex;
  margin-bottom: 32px;
}

.filter {
  width: 300px;
  margin-right: 24px;
  background: $white;

  &:last-child {
    margin-right: 0;
  }
}
