@import "../../../../../common/styles";

.content {
  margin-bottom: 24px;
}

.itemTitle {
  font-weight: 600;
}

.checkboxWrapper {
  margin-bottom: 24px;
}

.modalFooterActions {
  display: flex;
  justify-content: space-between;
}
