@import "../../../../common/styles/index";

.navigationWrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;

  .sectionWrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .footerNavigation {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;
  }

  .logo {
    width: 227px;
    padding: 37px 50px 23px 23px;
  }

  .navButton {
    justify-content: start;
    padding-left: 27px;
    text-transform: none;
    width: 100%;

    &:global(.active) {
      background: $blue-light;
      color: $blue;
    }

    :global(.MuiButton-startIcon) {
      width: 35px;
    }
  }

  .navButtonWithoutIcon {
    justify-content: start;
    padding-left: 66px;
    text-transform: capitalize;
  }
}
