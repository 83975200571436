@import "../../../../../../common/styles";

.thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.noPhotoExample {
  @extend %body14;
  height: 145px;
  color: $black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $black;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;

  .imageWrapper {
    position: relative;
    width: calc(100vw - 300px);
    height: calc(100vh - 300px);
    object-fit: contain;
    display: flex;
  }

  .fullscreen {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }

  .closeButton {
    position: absolute;
    right: -45px;
    top: -45px;

    .closeIcon {
      color: $white;
      font-size: 44px;
    }
  }
}
