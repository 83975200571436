%h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 50px;
  letter-spacing: 0.05em;
}

%h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.05em;
}

%h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
}

%h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.1px;
}

%h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.05em;
}

%h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.05em;
}

%body1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
}

%body2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

%body14 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.0025em;
}

%body16 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
}

%body14-600 {
  @extend %body14;
  font-weight: 600;
}

%body14-400 {
  @extend %body14;
  font-weight: 400;
}

%body16-600 {
  @extend %body16;
  font-weight: 600;
}

%body16-700 {
  @extend %body16;
  font-weight: 700;
}

%body22 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
}
