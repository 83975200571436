$white: #ffffff;
$black: #263238;
$dark-grey: #747474;
$light-grey: #c4c4c4;
$light-grey-2: #f4f6f9;
$lighten-grey: lighten($light-grey, 20%);
$blue: #0058dd;
$blue-2: #6ca0ef;
$blue-light: lighten(#0058dd, 50%);
$blue-light-2: #dbedff;

$error: #d32f2f;
$green: #00ac11;

$grid-hover: #f6f8fa;
