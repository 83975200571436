@import "../../../styles";

.title {
  @extend %body16;
  margin: 0;
  color: $black;
  font-weight: 600;
  display: flex;
  align-items: center;

  span {
    margin: 0 16px;
    overflow-wrap: anywhere;
  }

  path {
    fill: $light-grey;
  }
}

.completed {
  path {
    fill: $green;
  }
}

.error {
  &.title,
  path {
    fill: $error;
    color: $error;
  }
}

div.filled {
  background: $blue-light-2;
  z-index: 1;
}

div.root {
  z-index: 1;
}

div.content {
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.checkIcon {
  display: flex;
  position: relative;

  &:before {
    display: block;
    content: " ";
    background: $white;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 4px;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
  }

  svg {
    position: relative;
    z-index: 1;
  }
}
