@import "../../../../../common/styles/index";

.gridWrapper {
  text-align: right;
  padding: 5px 0;
  p {
    margin: 0;
  }
}

.firstColumn {
  text-align: left;
}

.row {
  padding: 15px 0;
}

.subtitle {
  font-size: 14px;
  color: $blue;
  padding-top: 10px;
}

.input {
  padding-left: 20px;
}

.bold {
  font-weight: 600;
}
