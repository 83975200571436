@import "../../../../../common/styles";

.row {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 32px;

  .filter {
    width: 320px;
    background: $white;

    &:last-child {
      margin-right: 0;
    }
  }

  .orgFilter {
    width: 320px;
    background: $white;
    margin-left: 16px;
  }
}
