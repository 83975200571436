@import "../../../../../../common/styles";

.viewWrapper {
  padding: 14px 28px;
}

.headerWrapper {
  width: 92%;
  text-align: center;
  border-bottom: 1px solid $blue;
}

.header {
  @extend %h6;
  color: $blue;
  padding: 20px;
  margin: 0;
}
