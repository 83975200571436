@import "../../../../../../common/styles/index";

.summaryWrapper {
  margin: 0 20px 0 30px;
}

.highlightedRow {
  text-align: right;
  font-weight: 600;
  border-top: 3px solid $blue;
  color: $blue;
}

.caseInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
}

.caseInfoColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.caseInfoRow {
  padding: 10px 0;
  margin: 0;
}

.caseInfoValue {
  font-weight: 600;
  padding-left: 30px;
}

.taxesSection {
  display: flex;
  justify-content: right;
}

.taxesWrapper {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.borderTop {
  border-top: 1px solid $light-grey;
}

.borderVertical {
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
}

.highlightedBold {
  color: $blue;
  font-weight: 600;
}

.bold {
  font-weight: 600;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.currencyWrapper {
  display: inline-block;
}
