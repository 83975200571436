@import "../../../../../../common/styles";

.viewWrapper {
  margin-top: 16px;
}

.accordionWrapper {
  margin: 20px 0;
}

.headerWrapper {
  padding: 14px 28px 16px 28px;
  background-color: $blue-light-2;
}

.viewSectionTitle {
  @extend %body16-600;
  color: $black;
  margin: 0;
}

.caseInfoContent {
  display: flex;
  align-items: flex-start;
}

.sellerInfo {
  margin-top: 12px;
}

.hr {
  margin-top: 28px;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid $light-grey;
}

.sectionSubTitle {
  @extend %body16-700;
  color: $black;
  padding-bottom: 24px;
  margin: 0;
}

.listItemName {
  font-weight: 700;
}

.listItemValue {
  font-weight: normal;
}
