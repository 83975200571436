@import "../../../../common/styles";

.wrapper {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px 6px 10px 22px;

  .logo {
    height: 60px;
    @media screen and (max-width: 1500px) {
      height: 40px;
    }
  }

  nav {
    a {
      @media screen and (max-width: 1440px) {
        padding: 10px;
      }

      &:not(:last-child) {
        margin-right: 30px;
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          top: 16px;
          bottom: 16px;
          right: -20px;
          border-right: 1px solid $black;
        }
      }
    }
    @media screen and (max-width: $extra-small) {
      display: none;
    }
  }

  .rightBlock {
    display: flex;
    align-items: center;

    @media screen and (max-width: $extra-small) {
      display: none;
    }
  }
}
