@import "../../../common/styles";

.serviceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px 40px;
}

.iconWrapper {
  display: flex;
  background-color: $blue;
  color: white;
  border-radius: 100%;
  padding: 18px;
}

.title {
  @extend %h6;
}

.text {
  @extend %body1;
  white-space: pre-line;
  color: $dark-grey;
}

.readMore {
  @extend %body1;
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}
