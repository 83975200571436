@import "../../../../../common/styles";

span.loader {
  flex: 1;
  align-self: center;
  display: flex;
}

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: auto;
  align-items: center;
}

.emptyViewImg {
  height: 28vw;
}

.placeholderSection {
  padding: 0 30px;
  text-align: center;

  .placeholderTitle {
    color: $black;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    max-width: 1125px;
    margin: 20px 0;
  }

  .subtitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 28px 0;
  }

  .subtitle {
    color: $black;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    max-width: 1125px;
    margin: 0;
  }

  .titleIcon {
    padding-right: 6px;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btn {
    margin: 0 8px;
  }

  .plusIcon {
    width: 16px;
    padding-left: 8px;
  }
}
