@import "../../../../../common/styles";

.addBrandWrapper {
  padding-top: 8px;

  .inputRoot {
    width: 320px;
    background: $white;
  }

  .button {
    padding: 28px 34px;
    margin-left: 16px;
  }
}
