@import "../../../../../../common/styles";

.viewWrapper {
  padding: 14px 28px;
}

.viewSectionTitle {
  @extend %body16-600;
  color: $black;
  padding-bottom: 16px;
  margin: 0 0 20px;
  border-bottom: 1px solid $light-grey;
}

.sectionWrapper {
  padding: 14px 28px 0;
  border-bottom: 1px solid $light-grey;
  margin: 0 -24px;

  &:last-of-type {
    border-bottom: none;
  }
}

.sectionContent {
  display: flex;
  align-items: flex-start;
  padding-bottom: 24px;
}

.hr {
  margin-top: 28px;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid $light-grey;
}

.sectionSubTitle {
  @extend %body16-700;
  color: $black;
  padding-bottom: 24px;
  margin: 0;
}

.shippingPreferences {
  padding-bottom: 12px;
}

.listItemName {
  @extend %body14;
  color: $black;
}

.listItemValue {
  @extend %body16-600;
  color: $black;
}
