@import "../../../../common/styles/index";

.aboutUsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 90px;

  .aboutUsImg {
    height: 839px;
    width: 800px;
    margin-right: calc((100vw - 1400px + 20px) / -2);

    @media screen and (max-width: 1380px) {
      margin-right: -20px;
    }
  }

  .title {
    @extend %h1;
    color: $black;
    margin: 0 0 32px;
  }

  .description {
    text-align: left;
    max-width: 588px;
    margin-right: 40px;
  }

  .content {
    @extend %body1;
    color: $dark-grey;
    margin-bottom: 48px;
  }

  .readMore {
    @extend %body1;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    display: inline;
  }
}
