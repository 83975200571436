.wrapper {
  padding: 20px;
  margin-bottom: 30px;
  max-width: 950px;
}

.reportWrapper {
  padding: 20px;
  margin-bottom: 30px;
  max-width: 1200px;
}

.header {
  max-width: 990px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}

.purchaseStatus {
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    margin-bottom: 12px;
  }
}
