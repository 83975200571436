.purchaseProfileWrapper {
  padding: 0;
}

.loadingWrapper {
  margin-top: 40px;
  text-align: center;
}

.viewWrapper {
  padding: 14px 28px;
}
