@import "../../../../../../common/styles";

.subTitle {
  @extend %body16-700;
  color: $black;
}

div.firstInput {
  min-width: calc(50% - 200px);
  max-width: calc(50% - 200px);
}

.flexRow {
  display: flex;

  > :global(.MuiFormControl-root):not(:last-child) {
    margin-right: 16px;
  }
}

.shortField {
  max-width: 110px;
}

.subtotal {
  max-width: 140px;
}

.flexAlign {
  display: flex;
  align-items: center;
}

.error {
  color: $error;
}

.brandsWrapper {
  margin-top: 16px;
  margin-right: 16px;
  width: calc(50% - 200px);
}
