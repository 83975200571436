@import "../../../../../common/styles";

button.deleteBtn {
  position: absolute;
  right: -10px;
  top: -10px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background: $white;
  z-index: 1;

  &:hover {
    background: $lighten-grey;
  }
}

.dragWrapper {
  margin-right: 24px;
  width: 350px;
  height: 56px;
  position: relative;
}

.preview {
  display: flex;
  align-items: center;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  height: 56px;
  padding-left: 8px;
}

.dropzone {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $blue-light;
  border: 1px dashed $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropHint {
  margin: 0 0 4px;
  padding-left: 12px;
  padding-right: 6px;
  @extend %body14;
}

.fileError {
  margin: 0 0 4px;
  @extend %body14;
  color: $error;
}

.placeholder {
  display: flex;
}

.dropIcon {
  color: $blue;
}

.dropLink {
  margin: 0;
  @extend %body14;
  color: $blue-2;
  text-decoration: underline;
  cursor: pointer;
}

.pfdPreview {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.fileName {
  font-size: 12px;
}
