@import "../../../../../common/styles";

.pageTitleWrapper {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 107px;
  background: #f6f8fa;
  z-index: 99;
  margin: 0 -5px;
  padding: 6px 5px 0 5px;
}

button.editBtn {
  background: $white;
}

button.customAction {
  margin-left: 16px;
}

.customActionText {
  white-space: nowrap;
}

.pageTitle {
  @extend %h6;
  color: $black;
  margin: 0 0 18px;
}

.pageSubTitle {
  @extend %body16;
  color: $black;
  margin: 0 0 30px;
}

span.loadingProfileName {
  display: inline-block;
  width: 240px;
  font-size: 20px;
}
