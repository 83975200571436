@import "../../../common/styles";

.portalLayout {
  display: flex;
  background: #f6f8fa;
  min-height: 100vh;
}

.topNavigation {
  position: sticky;
  top: 0;
  background: white;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  background: $white;
  z-index: 999;
  align-items: center;
}

.rightBlock {
  margin-left: auto;
}

.notificationIcon {
  margin-right: 24px;
}

button.topNavigationBtn {
  margin-right: 24px;
}

.portalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portalMainSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
}
