@import "../../../../../../common/styles";

.registerSuccessWrapper {
  margin: 0 auto 100px;
  padding-top: 60px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $extra-small) {
    flex-direction: column;
    margin-bottom: 40px;
    padding-top: 40px;
    min-height: 30vh;
  }

  .registerSuccessImg {
    max-width: 50%;
    margin-right: 132px;

    @media screen and (max-width: 1480px) {
      margin-right: 40px;
    }

    @media screen and (max-width: $extra-small) {
      display: none;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 566px;
  }

  .title {
    @extend %h4;
    text-align: left;
    color: $black;
    margin: 0 0 16px;

    @media screen and (max-width: $extra-small) {
      margin-top: 16px;
      font-size: 24px;
      text-align: center;
    }
  }

  .error {
    @extend %h4;
    color: $error;
    text-align: left;
  }

  .successWrapper {
    text-align: left;
  }

  .successTitle {
    @extend %h4;
    color: $black;
    margin: 0 0 16px;
  }

  .successSubTitle {
    @extend %body16;
    color: $black;
    margin: 0 0 16px;
  }

  .subtitle {
    @extend %body16;
    text-align: left;
    color: $black;
  }
}
