@import "../../../../../common/styles";

.notes {
  @extend %body14;
  margin: 24px 0;
  border-left: 3px solid #c2c2c2;
  padding-left: 10px;
  color: $black;
  font-style: italic;
  overflow-wrap: anywhere;
}

.noPadding {
  margin: 0 0 1px;
}
