@import "../../../../../../common/styles";

.accordionWrapper {
  margin: 10px 0;

  .viewSectionTitle {
    @extend %body16-600;
    color: $black;
    margin: 0;
  }

  .productContent {
    padding: 14px 28px 16px 28px;
  }

  .sectionContent {
    display: flex;
    align-items: flex-start;
    padding-bottom: 24px;
  }

  .headerWrapper {
    padding: 14px 28px 16px 28px;
    background-color: $light-grey-2;
  }

  .listItemName {
    font-weight: 700;
  }

  .listItemValue {
    font-weight: normal;
  }

  .tabsWrapper {
    padding: 14px 28px 16px 28px;
    background-color: $lighten-grey;
  }

  .tabItem {
    color: $blue;
    border-bottom: 1px solid $blue;
  }

  .tabContent {
    display: flex;
    align-items: flex-start;
    padding: 30px 20px;
    margin: 10px 0;
  }

  .tabPhotoProfileContent {
    padding: 0 20px;
    margin: 10px 0;
  }
}
