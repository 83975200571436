@import "../../styles";

.languageSelect {
  margin-right: 30px;
  text-transform: uppercase;

  :global(.MuiInputBase-input) {
    font-weight: 500;
    color: $black;
  }

  :global(.MuiInputBase-input:focus) {
    background: none;
  }
}
