@import "../../../../../../common/styles";

.footer {
  margin-top: 50px;
  display: flex;
  justify-content: right;
}

.footerLabel {
  @extend %body16;
  color: $black;
  margin: 0 0 16px;
}

button.previewProfile {
  background: $white;
  margin-right: 24px;
}

.modalFooterActions {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}
