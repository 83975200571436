@import "../../../../../../common/styles";

.photoSection {
  display: flex;
  margin-bottom: 32px;
}

.photoPreview {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 24px;
}

.sectionContent {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
