.productPhotoWrapper {
  margin-bottom: 32px;
}

.productPhotoProfilesSection {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

div.productPhotoProfilesWrapper {
  margin-top: 16px;
  margin-right: 16px;
  width: calc(50% - 200px);
}

.productPhotoProfilesSettings {
  margin-top: 16px;
}
