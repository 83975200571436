@import "../../../../../../common/styles/";

div.purchaseProfilesWrapper {
  background: white;
  width: 400px;
  margin-right: 16px;
}

.storageWrapper {
  display: flex;
  align-items: center;
  margin: 18px 0 32px;
}

.storageAndShippingWrapper {
  padding-top: 24px;
  border-top: 1px solid $light-grey;
}
