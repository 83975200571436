.wrapper {
  width: 600px;
}

.text {
  margin-bottom: 24px;
  white-space: pre-line;
}

.modalFooterActions {
  display: flex;
  justify-content: space-between;
}
