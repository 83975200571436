@import "../../../../../../common/styles";

.iframeWrapper {
  iframe {
    height: 534px;
    min-width: 100%;
  }
}

.addPaymentModal {
  width: 800px;
  height: 550px;
}

.loading {
  display: none;
}

.loadingWrapper {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoWrapper {
  display: flex;
}

.logo {
  height: 50px;
}
