.homeWrapper {
  text-align: center;
  min-height: 100vh;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .routerLayout {
    flex: 1;
  }
}
