@import "../../../../../common/styles";

.list {
  padding: 0;
  margin: 0 12px 0 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 12px;
  row-gap: 18px;
  list-style: none;
  flex: 1;
}
