@import "../../../../../common/styles";

.listItemName {
  @extend %body14;
  color: $black;
}

.listItemValue {
  @extend %body16-600;
  color: $black;
  position: relative;
}

.notes {
  @extend %body14;
  color: $black;
}

.currencyText {
  font-weight: 600;
}

.secure {
  letter-spacing: 2px;
  line-height: 22px;
  height: 21.5px;
  display: inline-block;
  font-family: password !important;
}

button.secureToggle {
  position: absolute;
  top: -10px;
  right: -50px;
}

.overflowWrap {
  overflow-wrap: anywhere;
}
