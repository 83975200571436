@import "../../../../../../common/styles/index";

.viewSectionTitle {
  @extend %body16-600;
  color: $black;
  padding-bottom: 16px;
  margin: 0 0 20px;
  border-bottom: 1px solid $light-grey;
}

.tabViewSectionTitle {
  border-bottom: none;
  padding-bottom: 0;
}

.sectionWrapper {
  padding: 14px 28px 0;
  border-bottom: 1px solid $light-grey;
  margin: 10px -24px;

  &:last-of-type {
    border-bottom: none;
  }
}

.sectionContent {
  display: flex;
  align-items: flex-start;
  padding-bottom: 24px;
  flex-direction: column;
}

.hr {
  margin-top: 28px;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid $light-grey;
}

.sectionSubTitle {
  @extend %body16-700;
  color: $black;
  padding-bottom: 24px;
  margin: 0;
}

.listItemName {
  font-weight: 700;
}

.listItemValue {
  font-weight: normal;
}

.listItemValueWrapper {
  min-width: 28px;
}
