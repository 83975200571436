.row {
  display: flex;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    flex: 1;
    margin-right: 24px;
    min-width: 300px;
    margin-top: 0;
  }

  .notes {
    flex: 1;
  }

  .attachBtn {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-right: 12px;
    width: 220px;
  }

  .fileName {
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .deleteBtn {
    margin-bottom: 8px;
  }

  .hidden {
    visibility: hidden;
  }
}

.dropzone {
  margin-right: 24px;
  width: 150px;
  height: 150px;
  background: #a7c9ff;
}

.nameSection {
  display: flex;
}

.photoInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
