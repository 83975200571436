@import "../../../../../../common/styles";

.wrapper {
  padding: 20px;
  margin-bottom: 30px;
  max-width: 950px;

  .paymentsTitle {
    margin: 0;
    font-weight: 700;
  }

  span.loader {
    margin: 20px;
  }
}

.feesText {
  margin: 24px 0;
}

.rightBtn {
  display: inline;
  margin-left: 20px;
}
