@import "../../../../../../common/styles/index";

.successWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: auto;
  align-items: center;

  p {
    margin: 0;
  }

  .wrapper {
    padding: 50px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 30px;
      font-weight: 600;
      color: $blue;
    }

    .subtitle {
      font-size: 16px;
      padding: 30px 0;
    }

    .secondSubtitleWrapper {
      border-top: 1px solid $light-grey;
    }
  }
}
