@import "../../../../common/styles/index";

.userNameSection {
  margin-left: 16px;
}

.userName {
  text-transform: capitalize;
  @extend %body2;
  color: $black;
  margin: 0;
  text-align: left;
}

.userEmail {
  text-transform: lowercase;
  @extend %body14;
  color: $black;
  margin: 0;
  text-align: left;
  opacity: 0.6;
}
