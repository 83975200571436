@import "../../../../../common/styles/index";

.registerSuccessWrapper {
  margin: 0 auto 100px;
  padding-top: 60px;
  display: flex;
  justify-content: center;

  .registerSuccessImg {
    max-width: 50%;
    margin-right: 132px;

    @media screen and (max-width: 1480px) {
      margin-right: 40px;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 566px;
  }

  .title {
    @extend %h4;
    text-align: left;
    color: $black;
    margin: 0 0 16px;
  }

  .subtitle {
    @extend %body16;
    text-align: left;
    color: $black;
    margin: 0 0 16px;
  }

  .footerActions {
    text-align: left;
  }
}
