@import "../../../common/styles";

.wrapper {
  width: 600px;
}

.modalFooterActions {
  display: flex;
  justify-content: space-between;
}

.formFooter {
  display: flex;
  justify-content: center;
  margin: 32px 0;
}

.contactInfo {
  @extend %body1;
  list-style-type: none;
  text-align: left;
  padding-bottom: 30px;

  li {
    display: flex;
    align-items: center;

    svg {
      margin-right: 28px;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  a {
    text-decoration: none;
    color: $black;
  }

  p {
    margin: 0;
  }
}
